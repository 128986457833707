<template>
    <div v-if="checkCorrectData" class="main">
        <div class="item"><img :src="lastCardTexture" alt="Last Card" /></div>
        <div class="item"><img :src="lastChoiceTexture" alt="Choice" /></div>
        <div class="item"><img :src="newCardTexture" alt="New Card" /></div>
    </div>
    <div v-else>
        <div class="change_card"><img :src="require(`@/assets/games/mini/hilo/change_card.png`)" alt="New Card" /></div>
    </div>
</template>

<script>
export default {
    props: {
        currentCards: {
            type: Array,
            required: true,
        },
        selectedIndex: {
            type: Number,
            required: true
        },
        selectsHistory: {
            type: Array,
            required: true,
        },
        selectedCardsHistory: {
            type: Array,
            required: true,
        },
    },
    computed: {
        checkCorrectData() {
            try {
                const value = this.currentCards[this.selectedIndex].value;
                const suit = this.currentCards[this.selectedIndex].suit;
                suit + value
                return true;
            } catch (error) {
                return false;
            }
        },
        lastCardTexture() {
            const length = this.selectedCardsHistory.length;
            const suit = this.selectedCardsHistory[length - 1].suit;
            const value = this.selectedCardsHistory[length - 1].value;
            return require(`@/assets/games/mini/hilo/` + suit + (value + 1) + `.png`);
        },
        newCardTexture() {
            const suit = this.currentCards[this.selectedIndex].suit;
            const value = this.currentCards[this.selectedIndex].value;
            return require(`@/assets/games/mini/hilo/` + suit + (value + 1) + `.png`);
        },
        lastChoiceTexture() {
            const length = this.selectsHistory.length;
            const choice = this.selectsHistory[length - 1];
            return require(`@/assets/games/mini/hilo/` + choice + `.png`);
        },
    },
    methods: {

    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 280);
        this.$store.dispatch('workspace/setWidth', 400);
    }
}
</script>

<style scoped>
.main {
    display: flex;
    justify-content: center;
}

.item {
    display: flex;
    align-items: center;
}

.change_card {
    width: 400px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
